<template>
    <v-navigation-drawer v-model="show" color="#fafafa" fixed :permanent="permanent" :expand-on-hover="permanent">
        <v-list>
            <v-list-item class="px-2">
            <v-list-item-avatar color="#4697a8">
                <!-- <v-img src="https://www.unify.eng.br/mail/gustavo_assinatura.png"></v-img> -->
                <span class="white--text text-h6">{{ $store.state.user_name[0] }}</span>
            </v-list-item-avatar>
            <v-list-item-content class="user-infos">
                <v-list-item-title class="user-name">
                <!-- Gustavo Borges -->
                {{ $store.state.user_name }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle>gustavo@unify.eng.br</v-list-item-subtitle> -->
                <v-list-item-subtitle>{{ $store.state.user_mail }}</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
            <router-link v-for="(item, i) in items" :key="i" :to="item.link">
                <v-list-item link :class="item.class">
                    <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </router-link>
        </v-list>
    </v-navigation-drawer>
</template>
  
<script>
    export default {
        name: 'MainMenu',
        props: {
            drawer: {
                type: Boolean
            }
        },
        data: () => ({
          permanent: null,
          show:null,
          items: [
            // {
            //   icon: 'mdi-wrench-outline',
            //   text: 'Gestão de chamados',
            //   link: '/report/requests',
            //   class: "requests"
            // },
            // {
            //   icon: 'mdi-toolbox-outline',
            //   text: 'Gestão de estoque',
            //   link: '/report/stock',
            //   class: "stock"
            // },
            // {
            //   icon: 'mdi-clipboard-check-outline',
            //   text: 'Gestão de rotinas',
            //   link: '/report/routines',
            //   class: "routines"
            // },
            // {
            //   icon: 'mdi-pool-thermometer',
            //   text: 'Gestão das piscinas',
            //   link: '/report/pool',
            //   class: "pool"
            // },
            {
              icon: 'mdi-power-plug-outline',
              text: 'Gestão de energia',
              link: '/report/energy',
              class: "energy"
            },
            {
              icon: 'mdi-water-outline',
              text: 'Gestão de água',
              link: '/report/water',
              class: "water"
            },
            {
              icon: 'mdi-propane-tank-outline',
              text: 'Gestão de nível de gás',
              link: '/report/gas-level',
              class: "gas"
            },
            {
              icon: 'mdi-gas-burner',
              text: 'Gestão de consumo de gás',
              link: '/report/gas-consumption',
              class: "gas"
            },
            {
              icon: 'mdi-water-well-outline',
              text: 'Gestão de poços',
              link: '/report/well',
              class: "well"
            },
          ],
        }),
        watch: {
            drawer: {
                handler() {
                    this.show = !this.show;
                }
            }
        },
        mounted() {
            this.permanent = (screen.width > 800);
        }
    }
</script>