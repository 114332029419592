<template>
    <v-speed-dial v-model="fab" bottom right direction="top" transition="scale">
        <template v-slot:activator>
            <v-btn v-model="fab" color="#FAFAFA" fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-grid</v-icon>
            </v-btn>
        </template>
            <v-btn fab dark small color="yellow darken-1">
                <v-icon>mdi-light-switch-off</v-icon>
            </v-btn>
            <v-btn fab dark small color="green darken-1">
                <v-icon>mdi-broadcast</v-icon>
            </v-btn>
            <v-btn fab dark small color="red darken-1" @click="$router.push('/report/energy')">
                <v-icon>mdi-file-chart-outline</v-icon>
            </v-btn>
            <v-btn fab dark small color="blue darken-1" @click="$router.push('/form')">
                <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
    </v-speed-dial>
</template>
  
<script>
    export default {
        name: 'MobileSpeedDial',
        props: {},
        components: {},
        data: () => ({
            fab: false,
        }),
        methods: {},
        mounted() {}
    }
</script>

<style lang="scss" scoped>
    .v-speed-dial {
        position: fixed;
        z-index:99999999;

        @media screen {
            @media (min-width:960px) {
                display:none;
            }
        }
    }

    .v-btn--floating {
        position: relative;
    }
</style>