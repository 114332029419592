<template>
    <v-app-bar color="#FAFAFA" fixed class="mobile-selector">
        <v-app-bar-nav-icon @click.prevent="changeDrawer"></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <svg class="mobile-menu-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.823 97.629">
            <g id="unify-plus-logo" transform="translate(0.001 0)">
                <g id="Camada_3" data-name="Camada 3" transform="translate(-0.001 0)">
                <path id="Caminho_1" data-name="Caminho 1" d="M15.229,44.077a19.378,19.378,0,0,0,1.341,7.239,18.438,18.438,0,0,0,3.7,5.9,18.062,18.062,0,0,0,5.528,4.017,15.4,15.4,0,0,0,6.7,1.5V75.9a31.849,31.849,0,0,1-12.653-2.507A32.94,32.94,0,0,1,9.494,66.5,31.962,31.962,0,0,1,0,43.544V0H15.229Z" transform="translate(0.001 0)" />
                <path id="Caminho_2" data-name="Caminho 2" d="M76.873,0V43.274c0,.395,0,.52-.05.376s-.056-.107-.056.107a.226.226,0,0,0,.056.163.232.232,0,0,1,.05.157,19.477,19.477,0,0,1-1.253,6.894A19.077,19.077,0,0,1,73.2,55.5l10.529,9.62c.458-.508.909-1.028,1.335-1.573a33.265,33.265,0,0,0,5.152-9.219A31.022,31.022,0,0,0,92.1,43.544V0Z" transform="translate(-27.324 0)" />
                <rect id="Retângulo_2" data-name="Retângulo 2" width="14.753" height="54.248" transform="translate(71.916 21.697)" />
                <path id="Caminho_3" data-name="Caminho 3" d="M181.589,48.862a20.807,20.807,0,0,0-3.911-8.467q-5.528-7.151-18.551-7.157A21.64,21.64,0,0,0,149,35.544l4.87,10.986a15.938,15.938,0,0,1,3.528-.382,12.993,12.993,0,0,1,6.186,1.2,7.151,7.151,0,0,1,3.134,3.315,13.605,13.605,0,0,1,1.141,4.895q.157,2.776.163,5.822V88.326h14.753V61.446A56.208,56.208,0,0,0,181.589,48.862Z" transform="translate(-55.619 -12.406)" />
                <path id="Caminho_4" data-name="Caminho 4" d="M289.988,0V12.691H278.162a5.014,5.014,0,0,0-4.324,2.175,10.24,10.24,0,0,0-1.517,5.966V75.945H257.906V43.074H250.06V31.028h7.809V20.18a19.766,19.766,0,0,1,1.554-7.815A20.474,20.474,0,0,1,263.81,5.91,20.168,20.168,0,0,1,278.162,0Z" transform="translate(-93.344 0)" />
                <path id="Caminho_5" data-name="Caminho 5" d="M297.57,49.51l6,12.045h4.155V49.51Z" transform="translate(-111.079 -18.481)" />
                <path id="Caminho_6" data-name="Caminho 6" d="M383.38,34.616l-.113.219q-1.842,5.014-3.579,9.708l-3.253,8.843q-5.433,15.191-9.714,26.1a82.4,82.4,0,0,1-9.169,17.736,30.633,30.633,0,0,1-11.011,10.2q-6.135,3.146-15.348,3.134H331V95.808h.219q2.82,0,5.045-.219a15.666,15.666,0,0,0,4.067-.921,12.952,12.952,0,0,0,3.416-1.949A14.97,14.97,0,0,0,346.73,89.4a34.38,34.38,0,0,0,2.82-4.769q1.41-2.82,2.933-6.725t3.359-9.062q1.842-5.152,4.124-11.663,1.742-4.876,3.666-10.422t4.23-12.039V34.61Z" transform="translate(-123.558 -12.919)" />
                <path id="Caminho_7" data-name="Caminho 7" d="M342.766,60.829l-9.275-26.1V34.62H317.98L335.754,78.5Z" transform="translate(-118.698 -12.923)" />
                <rect id="Retângulo_3" data-name="Retângulo 3" width="14.427" height="12.691" transform="translate(134.982 0)" />
                <rect id="Retângulo_4" data-name="Retângulo 4" width="14.427" height="54.248" transform="translate(134.982 21.697)" />
                </g>
            </g>
        </svg>

        <v-spacer></v-spacer>

        <v-menu left bottom :nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-card color="#fafafa">
                <v-list color="#fafafa">
                    <div class="menu-action">
                        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined color="rgba(17,17,17)">
                                    Gerenciar conta
                                </v-btn>
                            </template>
                            <ManageAccount @close="dialog = false" />
                        </v-dialog>
                    </div>

                    <div class="menu-action">
                        <v-btn @click.prevent="logout()" class="ma-2" outlined color="error">
                            Sair
                        </v-btn>
                    </div>
                </v-list>
            </v-card>
        </v-menu>
    </v-app-bar>
</template>
  
<script>
    import ManageAccount from './ManageAccount.vue';

    export default {
        name: 'MobileSelector',
        components: {
            ManageAccount
        },
        data: () => ({
            gradient: "to top right, rgba(255,255,255,.7), rgba(0,0,0,.7)",
            dialog: false
        }),
        methods: {
            changeDrawer() {
                this.$emit('drawer');
            },
            logout() {
                localStorage.removeItem('__upslavierobaixio.token');
                this.$store.commit('setAuthentication', false);
                this.$router.push('/login');
            }
        },
        mounted() {}
    }
</script>