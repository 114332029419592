<template>
  <v-app>
    <v-main>
      <DesktopSelector v-if="isAuthenticated && !isForm" />
      <MainMenu v-if="isAuthenticated && isManager && !isForm" :drawer="drawer" />
      <MobileSelector v-if="isAuthenticated && isManager && !isForm" @drawer="receiveDrawer" />
      <MobileSpeedDial v-if="isAuthenticated && isManager" />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import DesktopSelector from '@/components/default/DesktopSelector.vue'
import MainMenu from '@/components/default/MainMenu.vue'
import MobileSelector from '@/components/default/MobileSelector.vue'
import MobileSpeedDial from '@/components/default/MobileSpeedDial.vue'
import { checkAuth } from '@/modules/commons.js'

//tremors: car-brake-alert

export default {
  name: 'App',
  components: {
    DesktopSelector,
    MainMenu,
    MobileSelector,
    MobileSpeedDial
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    isManager() {
      return checkAuth(this.$store.state.user_roles)
    },
    isForm() {
      return this.$route.path.startsWith('/form');
    },
  },
  data: () => ({
    drawer:true
  }),
  methods: {
    receiveDrawer() {
      this.drawer = !this.drawer;
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
  @import '@/styles/default.scss';
  @import '@/styles/historic-barchart.scss';
  @import '@/styles/historic-heatmap.scss';
  @import '@/styles/loading.scss';
  @import '@/styles/menu.scss';
  @import '@/styles/metrics.scss';
  @import '@/styles/room-card.scss';
  @import '@/styles/routine-table.scss';
  @import '@/styles/stock-table.scss';
  @import '@/styles/switch.scss';
  @import '@/styles/thermometer.scss';
  @import '@/styles/treatment-chart.scss';
  @import '@/styles/treatment-table.scss';
  @import '@/styles/requests-table.scss';
  @import '@/styles/requests-ranking.scss';
  @import '@/styles/requests-pie-chart.scss';
  @import '@/styles/forms.scss';

  .mobile-menu-logo {
    max-height:50%;
  }

  .report {
    .v-navigation-drawer {
      .alarms,
      .cold,
      .energy,
      .gas,
      .hot,
      .power,
      .rooms,
      .water {
        display:flex;
      }

      .hvac,
      .lights {
        display:none;
      }
    }
  }

  .control {
    .v-navigation-drawer {
      .alarms,
      .cold,
      .energy,
      .gas,
      .hot,
      .power,
      .rooms,
      .water {
        display:none;
      }

      .hvac,
      .lights {
        display:flex;
      }
    }
  }
</style>