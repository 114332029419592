import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if(store.state.user_roles) {
        const user_roles = store.state.user_roles.split(",");

        if(user_roles.includes('admin')) {
          return '/report/energy';
        } else if(user_roles.includes('checklists')) {
          return '/form';
        }
      } else {
        return '/login';
      }
    }
  },
  {
    path: '/report/energy',
    name: 'energy',
    component: () => import('@/views/reports/EnergyReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'energia' }
  },
  {
    path: '/report/water',
    name: 'water',
    component: () => import('@/views/reports/WaterReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'água' }
  },
  {
    path: '/report/gas-level',
    name: 'gas-level',
    component: () => import('@/views/reports/GasLevelReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'gás' }
  },
  {
    path: '/report/gas-consumption',
    name: 'gas-consumption',
    component: () => import('@/views/reports/GasConsumptionReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'gás' }
  },
  {
    path: '/report/well',
    name: 'well',
    component: () => import('@/views/reports/WellReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'poço' }
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/forms/LinkTree.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' },
  },
  {
    path: '/form/well/coco',
    name: 'form_well_coco',
    component: () => import('@/views/forms/well/CocoForm.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/well/lemon',
    name: 'form_well_lemon',
    component: () => import('@/views/forms/well/LemonForm.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/well/store',
    name: 'form_well_store',
    component: () => import('@/views/forms/well/StoreForm.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/well/one',
    name: 'form_well_one',
    component: () => import('@/views/forms/well/NumberOneForm.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/well/two',
    name: 'form_well_two',
    component: () => import('@/views/forms/well/NumberTwoForm.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/water/aldeola',
    name: 'form_water_aldeola',
    component: () => import('@/views/forms/water/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/water/ponta-de-inhambupe',
    name: 'form_water_ponta_de_inhambupe',
    component: () => import('@/views/forms/water/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/gas/ponta-de-inhambupe',
    name: 'form_gas_ponta_de_inhambupe',
    component: () => import('@/views/forms/gas/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/gas/aldeola',
    name: 'form_gas_aldeola',
    component: () => import('@/views/forms/gas/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/gas/beach-club',
    name: 'form_gas_beach_club',
    component: () => import('@/views/forms/gas/BeachClub.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/energy/ponta-de-inhambupe',
    name: 'form_energy_ponta_de_inhambupe',
    component: () => import('@/views/forms/energy/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/energy/aldeola',
    name: 'form_energy_aldeola',
    component: () => import('@/views/forms/energy/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pool/aldeola',
    name: 'form_pool_aldeola',
    component: () => import('@/views/forms/pool/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pool/ponta-de-inhambupe',
    name: 'form_pool_ponta_de_inhambupe',
    component: () => import('@/views/forms/pool/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pool/house-one',
    name: 'form_pool_house_one',
    component: () => import('@/views/forms/pool/HouseOne.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pool/house-two',
    name: 'form_pool_house_two',
    component: () => import('@/views/forms/pool/HouseTwo.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pool/house-three',
    name: 'form_pool_house_three',
    component: () => import('@/views/forms/pool/HouseThree.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/spa',
    name: 'form_spa',
    component: () => import('@/views/forms/spa/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/chambers',
    name: 'form_chambers',
    component: () => import('@/views/forms/chambers/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/boiler',
    name: 'form_boiler',
    component: () => import('@/views/forms/boiler/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/grease-trap',
    name: 'form_grease_trap',
    component: () => import('@/views/forms/grease-trap/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/gym',
    name: 'form_gym',
    component: () => import('@/views/forms/gym/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/level/aldeola',
    name: 'form_level_aldeola',
    component: () => import('@/views/forms/level/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/level/ponta-de-inhambupe',
    name: 'form_level_ponta_de_inhambupe',
    component: () => import('@/views/forms/level/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/lights/aldeola',
    name: 'form_lights_aldeola',
    component: () => import('@/views/forms/lights/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/lights/ponta-de-inhambupe',
    name: 'form_lights_ponta_de_inhambupe',
    component: () => import('@/views/forms/lights/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/irrigation/aldeola',
    name: 'form_irrigation_aldeola',
    component: () => import('@/views/forms/irrigation/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/irrigation/ponta-de-inhambupe',
    name: 'form_irrigation_ponta_de_inhambupe',
    component: () => import('@/views/forms/irrigation/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pumps/aldeola',
    name: 'form_pumps_aldeola',
    component: () => import('@/views/forms/pumps/PousadaAldeola.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/pumps/ponta-de-inhambupe',
    name: 'form_pumps_ponta_de_inhambupe',
    component: () => import('@/views/forms/pumps/HotelPontaDeInhambupe.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/form/requests',
    name: 'form_requests',
    component: () => import('@/views/forms/requests/Form.vue'),
    meta: { requiresAuth: true, requiredRole: 'checklists' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('__upslavierobaixio.token');

      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/account/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/account/ForgotView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/account/reset-password',
    name: 'reset-password',
    component: () => import('@/views/account/ResetView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/UnauthorizedView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('__upslavierobaixio.token')) {
      next({ path: '/login' });
    } else {
      if (to.meta.requiredRole) {
        if(store.state.user_roles) {
          console.log(store.state.user_roles);
          const user_roles = store.state.user_roles.split(",");

          if((!user_roles.includes(to.meta.requiredRole) && !user_roles.includes('admin'))) {
            return next('/unauthorized');
          }
        }
      }
      next();
    }
  } else {
    next();
  }
});

export default router
