import axios from 'axios';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('Erro na requisição:', error);
    }
);

export function obtainDates() {
    const today = new Date();

    const last = new Date();
    last.setDate(today.getDate() - 7);

    const formatDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date_today = today.toLocaleDateString('pt-BR', formatDate).split('/').reverse().join('-');
    const date_last = last.toLocaleDateString('pt-BR', formatDate).split('/').reverse().join('-');

    return [date_last, date_today];
}

export function checkAuth(roles) {
    const required_roles = ['admin', 'energia', 'água', 'gás', 'rotinas', 'estoque', 'chamados', 'piscina', 'poço'];
    const actual_roles = roles.split(',');

    return required_roles.some(item => actual_roles.includes(item));
}