<template>
    <v-card>
        <v-toolbar dark color="#FAFAFA">
          <v-toolbar-title>Gerenciar conta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="close()">
                <v-icon color="#111">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Troca de senha</v-subheader>
          <v-list-item>
            <v-list-item-content class="new-password">
                <div class="content">
                    <v-text-field
                        class="new-password-field"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                        v-model="password" label="Nova senha" outlined
                    ></v-text-field>
                </div>
                <div class="content">
                    <v-btn class="new-password-submit" elevation="2" @click.prevent="changePassword()">Enviar nova senha</v-btn>
                </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-snackbar v-model="error.show" timeout="5000">
            {{ error.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="#DD0000" text v-bind="attrs" @click="error.show = false">
                    Entendi
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>
  
<script>
    export default {
        name: 'ManageAccount',
        props: {},
        data: () => ({
            password:null,
            show:false,
            error: {
                show:false,
                text: null
            }
        }),
        methods: {
            close() {
                this.$emit('close');
            },
            changePassword() {
                var self = this;
                if(this.password) {
                    this.$axios.post('https://auth.backend.baixio.slaviero.unify.plus/api/auth/change-password', {
                        password: this.password
                        }, {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
                            }
                        }).then(() => {
                            localStorage.removeItem('accessToken');
                            self.$store.commit('setAuthentication', false);
                            self.$router.push('/login');
                        }).catch(() => {
                            self.error.text = 'Houve um erro ao realizar a troca de senha.';
                            self.error.show = true;
                        });
                }
            }
        },
        mounted() {}
    }
</script>

<style lang="scss">
    .new-password {
        display:flex;
        justify-content: center;
        align-items:center;
        flex-wrap:wrap;

        .content {
            flex:100%;
            width:100%;

            .new-password-field,
            .new-password-submit {
                @media screen {
                    @media (max-width:800px) {
                        max-width:100%;
                        width:100%;
                    }
                    @media (min-width:800px) {
                        max-width:25%;
                        width:25%;
                    }
                }
            }
        }
    }

    .v-toolbar__title {
        color:#111 !important;
    }
</style>